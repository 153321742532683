import { Outlet, Link } from "react-router-dom";
import { RootState } from "../store/reducers";
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { namespaces } from "../i18n/i18n.constants";

export default function PanelUsuario(){
  const route = useSelector((state: RootState) => state.route);
  const { t } = useTranslation(namespaces.layouts);
  return (
  <div>
  <h3 className="color-platform mb-5">{t("user.user")}</h3>
    <nav className="subtitle-dashboard d-none d-xl-block">
      <Link className={route.sub === "tus-datos" ? 'active' : ''} to="/usuario/tus-datos">{t("user.data")}</Link>
      <Link className={route.sub === "cambiar-clave" ? 'active' : ''} to="/usuario/cambiar-clave">{t("user.changePassword")}</Link>
    </nav>
    <Outlet />
  </div>)
} 