import configs from '../config';
import store from '../store';

export default function Api(resource: string, jwt?: string) {
  let url = configs.ROUTES_API[0] + resource + ".php";
  const myHeaders = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${jwt ? jwt : store.getState().auth.jwt}`,
  };
  return ({
    post: (body = {}, contentType = "application/json") =>
      fetch(url, {
        method: "POST",
        headers: contentType === 'application/json' ? myHeaders : { Authorization: myHeaders.Authorization, 'Content-Type': contentType },
        body: JSON.stringify(body),
        cache: 'no-cache'
      })
        .then(async (res) => {
          if (contentType === "application/json") {
            const response = await res.json();
            if (response?.mensaje?.codigo === "999" && !jwt) {
              localStorage.clear();
              window.location.href = "";
            }
            return response;
          }
          if (contentType === "text/plain") {
            return res.text();
          }
          return res.text();
        })
        .catch((err) => {
          url = configs.ROUTES_API[1] + resource + ".php";
          return fetch(url, {
            method: "POST",
            cache: 'no-cache',
            headers: contentType === 'application/json' ? myHeaders : { Authorization: myHeaders.Authorization, 'Content-Type': contentType },
            body: JSON.stringify(body),
          })
            .then(res => {
              if (contentType === "application/json") {
                return res.json();
              }
              return res.text();
            })
        })
  });
};