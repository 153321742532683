import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import Api from '../../utils/api';
import Button from '../Button';
import Modal from '../Modal'

interface IProps {
  show: boolean,
  onClose: Function,
  hasMercadoPago: boolean,
  cuotas: Array<{ NU_TALON: string; IMP_PAGAR: number }>,
  // Pago online
  hasPagoTarjeta: boolean,
  onLoadPagoTarjeta: boolean,
  clickPagoTarjeta: Function,
}

export default function ModalPagoOnline({ show, hasMercadoPago, hasPagoTarjeta, onClose, cuotas, clickPagoTarjeta, onLoadPagoTarjeta }: IProps) {
  const [onLoadMP, setOnLoadMP] = useState(false);
  const { id = "-1" } = useParams();

  const handleClickMP = () => {
    setOnLoadMP(true);
    let ptalones = cuotas.reduce((acc: string, e) => acc + `${e.NU_TALON},`, '');
    const pimporte = cuotas.reduce((acc: number, e) => acc + e.IMP_PAGAR, 0);
    ptalones = ptalones.slice(0, -1);

    Api("consumir")
      .post({
        param: {
          pco_pasajero: id,
          pimporte,
          ptalones,
        },
        servicio: "pagarMercadoPagoInicio"
      }).then(res => {
        if (!res.PPAGOTOKEN) {
          return;
        }
        redirectMP(res.PPAGOTOKEN);
      })
  };

  const redirectMP = (pagoToken: string) => {
    Api("pagoMercadoPago")
      .post({
        pagoToken
      }).then(res => {
        onClose();
        if (!res.url) {
          return;
        }
        window.location.href = res.url;
      }).finally(() => {
        setOnLoadMP(false);
      });
  };

  return (
    <Modal title="Pago online" onClose={() => onClose()} show={show}>
      <h5 className="text-center my-3">Selecciona el método de pago</h5>
      <div>
        <div className="my-2">
          {
            hasMercadoPago
              ? <Button block onLoad={onLoadMP} title="Mercado Pago" onPress={() => handleClickMP()} />
              : <></>
          }
        </div>
        <div className="my-2">
          {
            hasPagoTarjeta
              ? <Button block onLoad={onLoadPagoTarjeta} title="Pago con tarjeta" onPress={() => clickPagoTarjeta()} />
              : <></>
          }
        </div>
      </div>
    </Modal>
  )
}
