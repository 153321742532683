import { Outlet, Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../store/reducers";
import IPrefPasajeros from "../interfaces/IPrefPasajeros";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { namespaces } from "../i18n/i18n.constants";

export default function PanelPasajero() {
  const [solapas, setSolapas] = useState<Array<string>>([]);
  // const [promoMensaje, setPromoMensaje] = useState("");
  const [pdf, setPdf] = useState("");
  let { id, nombre } = useParams();

  const route = useSelector((state: RootState) => state.route);
  const { t } = useTranslation(namespaces.layouts);

  const listPasajeros: IPrefPasajeros[] = useSelector((state: RootState) => state.pasajeros.pasajeros);
  useEffect(() => {
    const pasajero = listPasajeros.find(e => e.CO_PASAJERO === id);
    if (!pasajero) {
      return;
    }
    // setPromoMensaje(pasajero.PROMO_MENSAJE);
    setSolapas(pasajero.SOLAPAS);
    setPdf(pasajero.D_PDF_TIPS);
  }, [id, listPasajeros]);
  return (
    <div>
      <h3 className="color-platform mb-5">{t("traveler.informationTraveler")}</h3>
      <nav className="subtitle-dashboard d-none d-xl-block">
        {
          solapas.find(e => e === "Datos")
            ? <Link className={route.sub === "datos-del-pasajero" ? "active" : ""} to={`/pasajero/${id}/${nombre}/datos-del-pasajero`}>{t("traveler.traveler")}</Link>
            : <></>
        }
        {
          solapas.find(e => e === "CuotasPagos")
            ? <Link className={route.sub === "cuotas-y-pago" ? "active" : ""} to={`/pasajero/${id}/${nombre}/cuotas`}>{t("traveler.payment")}</Link>
            : <></>
        }
        {
          solapas.find(e => e === "Grupos")
            ? <Link className={route.sub === "grupo-de-viaje" ? "active" : ""} to={`/pasajero/${id}/${nombre}/grupo-de-viaje`}>{t("traveler.travelGroup")}</Link>
            : <></>
        }
        {
          solapas.find(e => e === "CuotaCero")
            ? <Link className={route.sub === "cuota-cero" ? "active" : ""} to={`/pasajero/${id}/${nombre}/cuota-cero`}>{t("traveler.zero")}</Link>
            : <></>
        }
        {
          solapas.find(e => e === "FichaMedica")
            ? <Link className={route.sub === "ficha-medica" ? "active" : ""} to={`/pasajero/${id}/${nombre}/ficha-medica`}>{t("traveler.medicalRecord")}</Link>
            : <></>
        }
        {
          solapas.find(e => e === "ServicioIndividual")
            ? <Link className={route.sub === "servicios-individuales" ? "active" : ""} to={`/pasajero/${id}/${nombre}/servicios-individuales`}>{t("traveler.service")}</Link>
            : <></>
        }
        {
          solapas.find(e => e === "Salida")
            ? <Link className={route.sub === "lugar-horario-salida" ? "active" : ""} to={`/pasajero/${id}/${nombre}/lugar-horario-salida`}>{t("traveler.placeAndTime")}</Link>
            : <></>
        }
        {
          solapas.find(e => e === "TipsParaTuViaje")
            ? <a className={route.sub === "tips-para-tu-viaje" ? "active" : ""} target="_blank" rel="noopener noreferrer" href={pdf}>{t("traveler.tips")}</a>
            : <></>
        }
        {
          solapas.find(e => e === "Concursos")
            ? <Link className={route.sub === "concursos" ? "active" : ""} to={`/pasajero/${id}/${nombre}/concursos`}>{t("traveler.competitions")}</Link>
            : <></>
        }
      </nav>
      <Outlet />
    </div>)
}