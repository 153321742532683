import React, { useRef, useState } from 'react'
import Button from '../../components/Button';
import estadiaPhoto from "../../assets/images/logo_bariloche.png";
import Api from '../../utils/api';
import formToObj from '../../utils/formToObj';
import numberToArr from '../../utils/numberToArr';
import OBJtoXML from '../../utils/objToXML';
import Modal from '../../components/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';

export default function Estadias() {
  const [claveValida, setClaveValida] = useState(false);
  const [apiMessage, setApiMessage] = useState("");
  const [apiMessagePax, setApiMessagePax] = useState("");
  const [onLoadLogin, setOnLoadLogin] = useState(false);
  const [onLoadPax, setOnLoadPax] = useState(false);
  const formClave = useRef<HTMLFormElement>(null);
  const [auth, setAuth] = useState({});
  const [messageSuccess, setMessageSuccess] = useState("");
  const [showModal, setShowModal] = useState(false);

  const [cantidadPax, setCantidadPax] = useState(0);

  const login = () => {
    if (!formClave.current) {
      return;
    }
    const body = formToObj(formClave.current);
    setApiMessage("");
    setOnLoadLogin(true);
    setAuth(body);
    Api("consumirST")
      .post({
        servicio: "estadiaInicio",
        param: body
      })
      .then(res => {
        setOnLoadLogin(false);
        if (res.mensaje.tipo !== "M") {
          setApiMessage(res.mensaje.descripcion);
          return;
        }
        setCantidadPax(+res.PN_CANT_PAX);
        setClaveValida(true);
      })
  }

  const postEstadia = () => {
    const paxs: any[] = []

    document.querySelectorAll('form[form-pax]').forEach((e: any) => {
      paxs.push(formToObj(e));
    });

    const prm_xml_pasajeros = OBJtoXML({
      PASAJEROS: {
        PASAJERO: paxs
      }
    });

    setApiMessagePax("");

    setOnLoadPax(true);
    Api("consumirST")
      .post({
        servicio: "estadiaGuardar",
        param: {
          ...auth,
          prm_xml_pasajeros
        }
      })
      .then(res => {
        setOnLoadPax(false);
        if (res.mensaje.tipo !== "M") {
          setApiMessagePax(res.mensaje.descripcion);
          return;
        }
        setMessageSuccess(res.message.descripcion);
        setShowModal(true);
      })
  }

  return (
    <div className="bg-login h-100 pb-5 px-3">
      <div className="bg-estadias-image" style={{ backgroundImage: `url(${estadiaPhoto})` }}></div>
      <div className="mx-auto my-auto bg-light rounded shadow-lg container-recuperar-pwd">
        <div className="row">
          <div className="col-12 bg-white rounded-2 p-md-5 p-2">
            <h1 className="color-platform text-center mb-5">Estadía</h1>
            {
              !claveValida
                ?
                <form ref={formClave}>
                  <div className="mb-3">
                    <input className="form-control" type="text" name="pn_ticket" placeholder="Número de ticket" required />
                  </div>
                  <div className="mb-3">
                    <input className="form-control" type="text" name="pc_clave" placeholder="Clave" required />
                  </div>
                  <div className="text-center mt-4">
                    <Button onLoad={onLoadLogin} title="Enviar" onPress={() => login()} />
                  </div>
                  <p className="text-center text-danger">{apiMessage}</p>
                </form>
                :
                <div>
                  {
                    numberToArr(cantidadPax).map((e: number) =>
                      <form form-pax={e} key={`pasajero_${e}`} className="px-3 px-md-5">
                        <h5 className="mb-3">Pasajero {e + 1}:</h5>
                        <div className="mb-3">
                          <span>Apellido</span>
                          <input className="form-control" type="text" name="APELLIDO" placeholder="Apellido" required />
                        </div>
                        <div className="mb-3">
                          <span>Nombre</span>
                          <input className="form-control" type="text" name="NOMBRE" placeholder="Nombre" required />
                        </div>
                        <div className="mb-3">
                          <span>Fecha de nacimiento</span>
                          <input className="form-control" type="date" name="FECHA_NACIMIENTO" placeholder="fecha de nacimiento" required />
                        </div>
                        <div className="mb-3">
                          <span>Teléfono</span>
                          <input className="form-control" type="tel" name="TELEFONO" placeholder="Télefono" required />
                        </div>
                        <input className="form-control d-none" type="text" name="TIPO_DOCUMENTO" defaultValue="4" />
                        <div className="mb-3">
                          <span>Celular</span>
                          <input className="form-control" type="tel" name="CELULAR" placeholder="Celular" required />
                        </div>
                        <div className="mb-3">
                          <span>Mail</span>
                          <input className="form-control" type="mail" name="MAIL" placeholder="Mail" required />
                        </div>
                        <div className="mb-3">
                          <span>Sexo</span>
                          <select className="form-select" name={`sexo`}>
                            <option value=""></option>
                            <option value="F">FEMENINO</option>
                            <option value="M">MASCULINO</option>
                          </select>
                        </div>
                        <hr />
                      </form>)
                  }
                  <div className="text-center">
                    <Button onLoad={onLoadPax} title="Enviar información" onPress={() => postEstadia()} />
                  </div>
                  <p className="text-center text-danger mt-5" dangerouslySetInnerHTML={{ __html: apiMessagePax }}></p>
                </div>
            }
          </div>
        </div>
      </div>
      <p className="text-white text-center fs-5 py-5" style={{ letterSpacing: '2px' }}>© {2022} Maxdream. Todos los derechos reservados.
      </p>
      <Modal title="" show={showModal} onClose={() => { }}>
        <div className="text-success text-center" style={{ fontSize: '5em' }}>
          <FontAwesomeIcon icon={faCircleCheck} />
        </div>
        <h3 className="text-center">Datos enviados correctamente</h3>
        <p className="text-center">{messageSuccess}</p>
        <div className="text-center mb-3">
          <Button title="Ir al inicio" onPress={() => { }} />
        </div>
      </Modal>
    </div>
  )
}
