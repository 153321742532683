import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Api from "../../utils/api";
import DownloadFile from "../../utils/downloadFile";
import { setRoute } from "../../store/actions/routeActions";
import { useDispatch } from 'react-redux';
import Button from "../../components/Button";
import { SkeletonTable } from "../../components/Skeleton";
import { faChevronDown, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import './CuotasYPago.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImageRapiPago from '../../assets/images/pagos/rp.png';
import ImagePagoMisCuentas from '../../assets/images/pagos/pmc.png';
import ImageProvinciaNet from '../../assets/images/pagos/pn.png';
import ModalPagoOnline from "../../components/serviciosIndividuales/ModalPagoOnline";
import ModalPagoTarjeta from "../../components/serviciosIndividuales/ModalPagoTarjeta";

interface ICuota {
  show: boolean,
  onImprimirFactura: boolean,
  BOTON_ACTUALIZAR: boolean;
  CLAVE_EMISION: string;
  CONCEPTO: string;
  D_IMPORTE: string;
  D_IMPORTE2: string;
  D_PAGO: boolean;
  D_PRI_VEN: string;
  D_SEG_VEN: string;
  D_SEN: string;
  D_VENCIDO: boolean;
  IMP_PAGAR: string;
  MONEDA: string;
  NU_FACTURA: string;
  NU_TALON: string;
}

export default function CuotasYPago() {
  const [activarMP, setActivarMP] = useState(false);
  const [minimoMP, setMinimoMP] = useState(0);
  const [activarPT, setActivarPT] = useState(false);
  const [minimoPT, setMinimoPT] = useState(0);
  const [importePagar, setImportePagar] = useState<number>(0);
  const [cuotas, setCuotas] = useState<Array<ICuota>>([]);
  const [cuotasFilter, setCuotasFilter] = useState<Array<ICuota>>([]);
  const [optionFilter, setOptionFilter] = useState("pagadas");
  const [cuotasPagar, setCuotasPagar] = useState<Array<{ NU_TALON: string; IMP_PAGAR: number }>>([]);
  const [showMetodoPago, setShowMetodoPago] = useState(false);
  const [printing, setPrinting] = useState(false);
  const [showPagoTarjeta, setShowPagoTarjeta] = useState(false);
  const [pagoToken, setPagoToken] = useState("");
  const [onLoad, setOnLoad] = useState(true);
  const [tarjetas, setTarjetas] = useState([]);
  const [listaCuotas, setListaCuotas] = useState([]);
  const [loadPagoTarjeta, setLoadPagoTarjeta] = useState(false);

  const { id = "-1", nombre } = useParams();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setRoute({
      section: 'pasajero',
      sub: 'cuotas-y-pago',
      cp: id,
    }));
  }, [dispatch, id]);

  useEffect(() => {
    setOnLoad(true);
    Api("consumir").post({
      servicio: "paxCuotas",
      param: {
        pco_pasajero: id,
      },
    }).then((res) => {
      setOnLoad(false);
      setMinimoMP(+res.PI_ACTIVAR_MERCADOPAGO);
      setMinimoPT(+res.PI_ACTIVAR_PAGOTARJETA);
      setCuotas(res.PREF_CUOTAS.map((cuota: any) => ({
        show: false,
        BOTON_ACTUALIZAR: cuota.BOTON_ACTUALIZAR === "S",
        CLAVE_EMISION: cuota.CLAVE_EMISION,
        CONCEPTO: cuota.CONCEPTO,
        D_IMPORTE: cuota.D_IMPORTE,
        D_IMPORTE2: cuota.D_IMPORTE2,
        D_PAGO: cuota.D_PAGO === "X",
        D_PRI_VEN: cuota.D_PRI_VEN,
        D_SEG_VEN: cuota.D_SEG_VEN,
        D_SEN: cuota.D_SEN,
        D_VENCIDO: cuota.D_VENCIDO === "X",
        IMP_PAGAR: cuota.IMP_PAGAR,
        MONEDA: cuota.MONEDA,
        NU_FACTURA: cuota.NU_FACTURA,
        NU_TALON: cuota.NU_TALON,
        onImprimirCuota: false,
      })));
      if (!res.PREF_CUOTAS.some((e: any) => !e.D_PAGO)) {
        setOptionFilter("pendientes");
      }
    });
  }, [id]);

  useEffect(() => {
    switch (optionFilter) {
      case "pagadas":
        setCuotasFilter(cuotas.filter(e => e.D_PAGO));
        break;
      case "pendientes":
        setCuotasFilter(cuotas.filter(e => !e.D_PAGO));
        break;
      default:
      case "todas":
        setCuotasFilter(cuotas);
        break;
    }

  }, [optionFilter, cuotas])

  function handleChangeFilter(e: React.ChangeEvent<HTMLSelectElement>) {
    setOptionFilter(e.target.value);
  }

  function handleTogleCuota(e: React.ChangeEvent<HTMLInputElement>) {
    const talon: string = e.target.name;
    const cuotaFinded = cuotas.find(e => e.NU_TALON === talon);
    if (!cuotaFinded) return;
    if (e.target.checked) {
      setCuotasPagar([...cuotasPagar, { NU_TALON: cuotaFinded.NU_TALON, IMP_PAGAR: +cuotaFinded.IMP_PAGAR }]);
      return;
    }
    setCuotasPagar(cuotasPagar.filter((cuota) => cuota.NU_TALON !== talon));
  }

  useEffect(() => {
    let total: number = 0;
    for (const cuotaPagar of cuotasPagar) {
      total += Number(cuotaPagar.IMP_PAGAR.toString().replaceAll(".", "").replace(",", "."));
    }
    setImportePagar(total);
    setActivarMP(total > minimoMP);
    setActivarPT(total > minimoPT);
  }, [cuotasPagar, cuotas, minimoMP, minimoPT]);

  function handleClickImprimir(talon: string) {
    setPrinting(true);
    Api("birt")
      .post({
        reporte: "SV_cuota.rptdesign",
        param: {
          prm_co_pasajero: id,
          prm_nu_talon: talon.toString(),
        },
      })
      .then((res) => {
        DownloadFile(res.reporte_base64, `${id} - Talon ${talon}`);
      })
      .finally(() => {
        setPrinting(false);
      });
  }

  const handleClickPagoTarjeta = () => {
    setLoadPagoTarjeta(true);
    Api("consumir")
      .post({
        param: {
          pco_pasajero: id,
          pimporte: cuotasPagar.reduce((acc: number, e) => acc + Number(e.IMP_PAGAR), 0),
          ptalones: cuotasPagar.reduce((acc: string, e) => acc + `${e.NU_TALON},`, ''),
        },
        servicio: "pagarDecidirInicio"
      }).then(res => {
        setShowMetodoPago(false);
        if (!res.PPAGOTOKEN) {
          return;
        }

        setPagoToken(res.PPAGOTOKEN);

        setTarjetas(res.PRM_LIS_TARJETAS.map((e: any) => ({
          CODIGO: e.CODIGO,
          PEDIR_TARJETA: e.PEDIR_TARJETA !== "N",
          TARJETA: e.TARJETA,
        })));

        setListaCuotas(res.PRM_LIS_CUOTAS.map((e: any) => ({
          CODIGO: e.CODIGO,
          CUOTAS: e.CUOTAS,
          DESCRIPCION: e.DESCRIPCION,
          IMP_ORIGINAL: Number(e.IMP_ORIGINAL),
        })));

        setShowPagoTarjeta(true);
      })
      .finally(() => {
        setLoadPagoTarjeta(false);
      })
  }

  const handleClickImprimirFactura = (factura: string) => {
    let _cuotas = cuotas.map((cuota: ICuota) => {
      if (cuota.NU_FACTURA === factura) {
        cuota.onImprimirFactura = true;
      }
      return cuota;
    });

    setCuotas(_cuotas);

    Api("consumir")
      .post({
        servicio: "facturaPDF",
        param: {
          pnu_factura: factura,
          pco_pasajero: id
        }
      }).then(res => {
        DownloadFile(res.PREF_FACTURA[0].PDF_BASE64, `${id} - Factura ${factura}`);
      }).finally(() => {
        let _cuotas = cuotas.map((cuota: ICuota) => {
          if (cuota.NU_FACTURA === factura) {
            cuota.onImprimirFactura = false;
          }
          return cuota;
        });

        setCuotas(_cuotas);
      })
  }

  const showCuota = (nutalon: string) => {
    const solicitud = cuotas.findIndex(e => e.NU_TALON === nutalon);
    let _cuotas = [...cuotas];
    _cuotas[solicitud].show = !_cuotas[solicitud].show;
    setCuotas(_cuotas);
  }

  return (
    <div className="container-data table-responsive">
      <ul>
        <li>Podés imprimir tu talón y abonarlo en las entidades de pago habilitadas:
          <img src={ImageRapiPago} alt="Rapi Pago" />
          <img src={ImagePagoMisCuentas} alt="Pago Mis Cuentas" />
          <img src={ImageProvinciaNet} alt="Provincia NET" />
        </li>
        <li>Podés abonar online seleccionando los talones a pagar y haciendo click en el botón de PAGO ONLINE.</li>
      </ul>
      <div className="form-inline">
        <div className="row my-4">
          <span className="col-auto mt-2">Ver</span>
          <select className="form-select col-auto" style={{ width: "auto" }} onChange={handleChangeFilter} value={optionFilter}>
            <option value="pendientes">Cuotas Pendientes</option>
            <option value="pagadas">Historial de Pagos</option>
            <option value="todas">Todos</option>
          </select>
        </div>
      </div>
      {
        onLoad
          ? <SkeletonTable />
          : <section className="section-cuota-y-pago">
            <div className="d-header-cuotas-y-pago-all">
              <span style={{ maxWidth: '4em' }}>Pago Online</span>
              <span>Talon</span>
              <span>Concepto</span>
              <span>1° Vencimiento</span>
              <span>1° Importe</span>
              <span>2° Vencimiento</span>
              <span>2° Importe</span>
              <span>Seña</span>
              <span>Pagado</span>
              <span>Vencido</span>
              <span style={{ maxWidth: '9em' }}>Imprimir</span>
            </div>
            <div className="d-grid-title">
              <span>Pago Online</span>
              <span>Talon</span>
              <span>Importe</span>
              <span>Pagado</span>
              <span></span>
            </div>
            {
              cuotasFilter.map((cuota: ICuota) => (
                <div className="d-grid-cuotas-y-pago" key={cuota.NU_TALON}>
                  <div onClick={() => showCuota(cuota.NU_TALON)} className="d-grid-title bg-dark-platform cursor-pointer">
                    <span>{cuota.CLAVE_EMISION ? 'Si' : ' '}</span>
                    <span>{cuota.NU_TALON}</span>
                    <span>{cuota.MONEDA + cuota.D_IMPORTE}</span>
                    <span className={cuota.D_PAGO ? "text-success text-center" : "text-danger text-center"}>{cuota.D_PAGO ? "✓" : "X"}</span>
                    <span><FontAwesomeIcon className={cuota.show ? 'r-90' : ''} icon={faChevronDown} /></span>
                  </div>
                  <div className={`d-grid-header  ${cuota.show ? 'show' : ''}`}>
                    <div>Pago Online</div>
                    <div>Talon</div>
                    <div>Concepto</div>
                    <div>1° Vencimiento</div>
                    <div>1° Importe</div>
                    <div>2° Vencimiento</div>
                    <div>2° Importe</div>
                    <div>Seña</div>
                    <div>Pagado</div>
                    <div>Vencido</div>
                    <div style={{ minHeight: '3em' }}>Imprimir</div>
                  </div>
                  <div className={`d-grid-body ${cuota.show ? 'show' : ''}`}>
                    <div className="container-checkbox-container-pago-online">{cuota.CLAVE_EMISION ? <div className="checkbox-container-pago-online d-flex justify-content-center pt-2"><input name={cuota.NU_TALON.toString()} onChange={handleTogleCuota} type="checkbox" /></div> : '.'}</div>
                    <div>{cuota.NU_TALON}</div>
                    <div>{cuota.CONCEPTO}</div>
                    <div>{cuota.D_PRI_VEN}</div>
                    <div className={cuota.D_PAGO ? "text-success" : cuota.D_VENCIDO ? "text-danger" : ""}>{cuota.MONEDA + cuota.D_IMPORTE}</div>
                    <div>{cuota.D_SEG_VEN}</div>
                    <div>{cuota.MONEDA + cuota.D_IMPORTE2}</div>
                    <div>{cuota.D_SEN !== "0" ? cuota.MONEDA + cuota.D_SEN : ""}</div>
                    <div className={cuota.D_PAGO ? "text-success text-center" : "text-danger text-center"}>{cuota.D_PAGO ? "✓" : "X"}</div>
                    <div className="text-danger text-center">{cuota.D_VENCIDO ? "Si" : "."}</div>
                    <div style={{ minHeight: '3em', maxWidth: '9em' }} className="text-center py-1">
                      {
                        cuota.BOTON_ACTUALIZAR
                          ? <Link to={`/pasajero/${id}/${nombre}/cuotas/actualizar`}><Button title="Actualizar" onPress={() => { }} /></Link>
                          : <></>
                      }
                      {
                        cuota.NU_FACTURA
                          ? <Button icon={faFilePdf} onLoad={cuota.onImprimirFactura} title={cuota.NU_FACTURA} onPress={() => handleClickImprimirFactura(cuota.NU_FACTURA)} />
                          : <></>
                      }
                      {cuota.CLAVE_EMISION && <Button title="imprimir" onLoad={printing} onPress={() => handleClickImprimir(cuota.NU_TALON)} />}
                    </div>
                  </div>
                </div>
              ))
            }
          </section>
      }
      {
        importePagar
          ? <div>
            <p className="alert alert-warning text-center">Importe neto a pagar: ${importePagar.toFixed(2)}</p>
            <div className="text-center">
              <Button title="Pago Online" block onPress={() => setShowMetodoPago(true)} />
            </div>
          </div>
          : <></>
      }
      {/* <Modal title="Selecciona el método de pago" onClose={() => setShowMetodoPago(false)} show={showMetodoPago}>
        <div className="d-flex justify-content-center my-3" style={{flexDirection: 'column', gap: 10}}>
          {activarMP ? <Button block title="Mercado Pago" onPress={() => {}} /> : <></>}
          {activarPT ? <Button block title="Pago con Tarjeta" onPress={() => {}} /> : <></>}
        </div>
      </Modal> */}
      <ModalPagoOnline
        cuotas={cuotasPagar}
        onLoadPagoTarjeta={loadPagoTarjeta}
        clickPagoTarjeta={() => handleClickPagoTarjeta()}
        hasPagoTarjeta={activarPT}
        hasMercadoPago={activarMP}
        onClose={() => setShowMetodoPago(false)} show={showMetodoPago}
      />
      <ModalPagoTarjeta
        pagoToken={pagoToken}
        onClose={() => setShowPagoTarjeta(false)}
        show={showPagoTarjeta}
        totalPagoOnline={10}
        cuotas={listaCuotas}
        tarjetas={tarjetas}
      />
    </div>)
}