export const namespaces = {
  pages: {
    login: "pages.login",
    recoveryPassword: "pages.recoveryPassword",
    register: "pages.register",
  },
  layouts: "layouts",
  common: "common",
};

export const languages = {
  es: "es",
};
