import { Action } from "redux";
import IAuth from "../../interfaces/IAuth";
import { LOG_IN, LOG_OUT, RELOAD_TIME } from "../types";
import config from '../../config'

let initialState: IAuth = {
  nombre: "",
  apellido: "",
  empresa: "",
  idUsuario: "",
  jwt: "",
  cambiarClave: false,
  email: "",
  sesionTime: 0,
};

let authLocalStorage: string = localStorage.getItem("auth") || "";
if (authLocalStorage) {
  try{
    const initial = JSON.parse(authLocalStorage);

    if (!initial.jwt) throw new Error("token del pasajero no encontrado");
    if (!initial.nombre) throw new Error("nombre del pasajero no encontrado");
    if (!initial.apellido) throw new Error("nombre del pasajero no encontrado");
    initialState = initial;
  }catch(err){
    localStorage.removeItem("auth");
  }
}

class LoginAction implements Action {
  type: string;
  payload: IAuth;
  constructor(type: string, payload: IAuth) {
    this.type = type;
    this.payload = payload;
  }
}

export default function authReducer(state: IAuth = initialState, action: LoginAction) {
  switch (action.type) {
    case LOG_IN:
      localStorage.setItem("auth", JSON.stringify({...state, ...action.payload}));
      return { ...state, ...action.payload };
    case LOG_OUT:
      localStorage.clear();
      return {
        nombre: "", apellido: "", empresa: "", idUsuario: "", jwt: "", cambiarClave: false, email: "", sesionTime: 0
      };
    case RELOAD_TIME:
      localStorage.setItem("auth", JSON.stringify({...state, sesionTime: Date.now() + config.SESION_TIME}));
      return { ...state, sesionTime: Date.now() + config.SESION_TIME };
    default:
      return state;
  }
}