import { namespaces } from "./i18n.constants";

export const es = {
  [namespaces.common]: {
    buttons: {
      ok: "Aceptar",
      cancel: "Cancelar",
    },
    session: {
      forgotYourPassword: "Ha olvidado su contraseña?",
      dontHaveAccount: "Todavía no tienes una cuenta?",
      register: "Regístrate",
      login: "Iniciar sesión",
      recoveryPassword: "Recuperar contraseña",
      sucursalVirtual: "Sucursal Virtual",
    },
    labels: {
      email: "Correo electrónico",
      lastName: "Apellido",
      firstName: "Nombre",
      documentType: "Tipo de documento",
      dni: "Número de documento",
      password: "Contraseña",
    },
  },
  [namespaces.layouts]: {
    user: {
      user: "Tu usuario",
      data: "Tus datos",
      changePassword: "Cambiar contraseña",
    },
    request: {
      request: "Tus solicitudes",
      yourRequest: "Solicitudes ingresadas",
      newRequest: "Solicitud nueva",
    },
    traveler: {
      informationTraveler: "Información para tu viaje",
      traveler: "Datos del pasajero",
      payment: "Cuotas y Pagos",
      travelGroup: "Grupo de viaje",
      zero: "Cuota cero",
      medicalRecord: "Ficha médica",
      service: "Servicios individuales",
      placeAndTime: "Lugar y horario de salida",
      tips: "Tips para tu viaje",
      formula: "La formula de la diversión",
      competitions: "Concursos"
    },
    login: {
      user: "Usuario",
      requests: "Solicitudes",
      turn: "Turno",
      logout: "Cerrar sesión",
    },
  },
  [namespaces.pages.login]: {
    titles: {
      login: "Iniciar sesión",
      email: "Correo electrónico - DNI - Código de pasajero",
    },
    buttons: {
      login: "Iniciar sesión",
      back: "Volver",
    },
    validateStay: "Valida tu estadía",
  },
  [namespaces.pages.recoveryPassword]: {
    titles: {
      recoveryPassword: "Recuperar contraseña",
      email: "Correo electrónico - DNI - Código de pasajero"
    },
    buttons: {
      recoveryPassword: "Recuperar contraseña",
    },
  },
  [namespaces.pages.register]: {
    titles: {
      register: "Registrar usuario",
    },
    buttons: {
      register: "Registrarse",
    },
  },
};
