// import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom"; // useSearchParams
// import NotFound from "../pages/NotFound";

export default function Public(){
  // const [searchParams] = useSearchParams();

  // const [key, setKey] = useState('');

  // useEffect(() => {
  //       const _key = searchParams.get("__key");
  //       setKey(_key ? String(_key) : '');
  // }, [searchParams])

  return (
    <Outlet />
    // <>
    // {
    //   key === process.env.REACT_APP_KEY || true
    //   ? <Outlet />
    //   : <NotFound message="" />
    // }
    // </>
  )
}