import { Outlet, Link } from "react-router-dom";
import { RootState } from "../store/reducers";
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { namespaces } from "../i18n/i18n.constants";

export default function PanelSolicitud(){
  const route = useSelector((state: RootState) => state.route);
  const { t } = useTranslation(namespaces.layouts);
  return (
  <div>
    <h3 className="color-platform mb-5">{t("request.request")}</h3>
    <nav className="subtitle-dashboard d-none d-xl-block">
      <Link className={route.sub === "tus-solicitudes" ? 'active' : ''} to="/solicitudes/tus-solicitudes">{t("request.yourRequest")}</Link>
      <Link className={route.sub === "nueva" ? 'active' : ''} to="/solicitudes/nueva">{t("request.newRequest")}</Link>
    </nav>
    <Outlet />
  </div>)
}