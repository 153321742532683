import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setRoute } from "../../store/actions/routeActions";
import Button from "../../components/Button";
import Api from "../../utils/api";
import { RootState } from "../../store/reducers";
import IPrefPasajeros from "../../interfaces/IPrefPasajeros";
import formToObj from "../../utils/formToObj";
import Modal from "../../components/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

export default function NewConcurso() {
  const [nombre, setNombre] = useState<string>("");
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [textFormInvalid, setTextFormInvalid] = useState('');
  const [apiError, setApiError] = useState('');
  const [onLoad, setOnLoad] = useState(false);
  const [textSuccess, setTextSuccess] = useState('');
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const formRef = useRef<HTMLFormElement | null>(null);
  const emailRef = useRef<HTMLInputElement | null>(null);


  const pasajeros = useSelector((e: RootState) => e.pasajeros);

  const { id = "-1", idconcurso = "-1" } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setRoute({
      section: 'pasajero',
      sub: 'concursos',
      cp: id,
    }));
    setNombre(pasajeros.pasajeros.find((p: IPrefPasajeros) => p.CO_PASAJERO === id)?.D_APEYNOM || "");
  }, [dispatch, id, pasajeros.pasajeros]);

  const handleRegisterCompetition = () => {
    navigate(`/pasajero/${id}/${nombre.replace("*", "").replaceAll(" ", "-")}/concursos`)
  }

  const createConcurso = () => {
    if (!formRef.current) return;

    if (!formRef.current.checkValidity()) return setTextFormInvalid('por favor, completa todos los campos.')
    setTextFormInvalid('');
    setShowModalConfirm(true);
  }

  const confirmCreateConcurso = () => {
    if (!emailRef.current) return;
    if (!formRef.current) return;



    if (emailRef.current.value !== (formRef.current.elements.namedItem('pd_email') as HTMLInputElement).value) return setApiError('Los email no coinciden');
    setApiError('');
    setOnLoad(true)

    const param = formToObj(formRef.current);
    param.pco_pasajero = id;
    param.pc_rifa = idconcurso;

    Api('consumir').post({
      servicio: 'rifaCarga',
      param
    }).then(res => {
      if (res.PM_ERROR === "E") return setApiError(res.PD_ERROR);
      setShowModalConfirm(false);
      setTextSuccess(res.PD_ERROR);
      setShowModalSuccess(true);
    })
      .catch(err => {
        setApiError(err.message);
      })
      .finally(() => {
        setOnLoad(false);
      })
  }

  return <div className="container-data my-4">
    <h3 className="text-center mb-4">Crear Concurso</h3>
    <form ref={formRef} style={{ maxWidth: '20em', margin: '0 auto', display: 'grid', gap: '1em' }}>
      <div className="form-group">
        <span>Apellido</span>
        <input type="text" className="form-control" placeholder="Apellido" name="pd_apellido" required />
      </div>
      <div className="form-group">
        <span>Nombre</span>
        <input type="text" className="form-control" placeholder="Nombre" name="pd_nombre" required />
      </div>
      <div className="form-group">
        <span>Tipo de Documento</span>
        <select className="form-select" name="pt_documento" required>
          <option></option>
          <option value="1">DNI</option>
          <option value="5">LC</option>
          <option value="4">LE</option>
          <option value="3">PASAPORTE</option>
        </select>
      </div>
      <div className="form-group">
        <span>Número de Documento</span>
        <input type="text" className="form-control" placeholder="Número de Documento" name="pn_documento" required />
      </div>
      <div className="form-group">
        <span>Email</span>
        <input type="text" className="form-control" placeholder="Email" name="pd_email" required />
      </div>
      <div className="form-group">
        <span>Número de Teléfono</span>
        <input type="tel" className="form-control" placeholder="Número de Teléfono" name="pn_telefono" required />
      </div>
      <p className="text-danger">{textFormInvalid}</p>
      <div className="form-group" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <Button onPress={() => navigate(`/pasajero/${id}/${nombre.replace("*", "").replaceAll(" ", "-")}/concursos`)} title="Volver" secondary />
        <Button onPress={createConcurso} title="Guardar" />
      </div>
    </form>
    <Modal title="Crear Concurso" onLoadAceptar={onLoad} onClose={() => setShowModalConfirm(false)} onAceptar={confirmCreateConcurso} show={showModalConfirm} btnAceptar="Confirmar" >
      <div>
        <div className="form-group">
          <label>Confirmar Email</label>
          <input type="email" className="form-control" placeholder="Email" ref={emailRef} />
        </div>
        <p className="text-danger">{apiError}</p>
      </div>
    </Modal>

    <Modal title="" btnAceptar="Volver" onClose={() => { }} onAceptar={handleRegisterCompetition} hideClose show={showModalSuccess}>
      <div className="text-center">
        <FontAwesomeIcon color="#20c997" icon={faCheckCircle} fontSize={80} />
        <h5 className="d-block mt-3">{textSuccess}</h5>
      </div>
    </Modal>
  </div>
}